<template>
	<div class="" >
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveAddOrderDialog"
			:width="width+'px'"
			:style="cssVars"
			append-to-body
			inline
			:before-close="handleCancel">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">
				<div class="required"></div><span>创建客户档案</span></span>
			
			<el-form :model="ruleForm" :rules="rules" size="mini" :inline="true" label-position="top" ref="ruleForm">
				<!--一行1个选项-->
				<!--<el-form-item label="testInput" prop="testInput" class="from-item-third">-->
				<!--	<el-input v-model.trim="ruleForm.testInput" autocomplete="off" clearable placeholder="testInput"></el-input>-->
				<!--</el-form-item>-->
				
				
				<!--一行2个选项两端对齐-->
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="客户姓名" prop="testInput" class="from-item-second">
						<el-input v-model.trim="ruleForm.testInput" autocomplete="off" clearable placeholder="testInput"></el-input>
					</el-form-item>
					
					<el-form-item label="客户性别" prop="testSelect">
						<el-select v-model="ruleForm.testSelect"
											 filterable
											 placeholder="testSelect"
											 @change="handleSelect">
							<el-option
								v-for="item in options"
								:key="item.id"
								:label="item.value"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				
				
				<!--一行2个选项两端对齐-->
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="客户年龄" prop="testInput" class="from-item-second">
						<el-input v-model.trim="ruleForm.testInput" autocomplete="off" clearable placeholder="testInput"></el-input>
					</el-form-item>
					
					<el-form-item label="客户手机" prop="testInput" class="from-item-second">
						<el-input v-model.trim="ruleForm.testInput" autocomplete="off" clearable placeholder="testInput"></el-input>
					</el-form-item>
				</div>
				
				<!--一行2个选项两端对齐-->
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="客户状态" prop="testInput" class="from-item-second">
						<el-select v-model="ruleForm.buy_status"
											 filterable
											 placeholder=""
											 @change="handleSelect">
							<el-option
								v-for="item in options"
								:key="item.id"
								:label="item.value"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="咨询产品" prop="testInput" class="from-item-second">
						<el-select v-model="ruleForm.goods_id"
											 filterable
											 placeholder=""
											 @change="handleSelect">
							<el-option
								v-for="item in options"
								:key="item.id"
								:label="item.value"
								:value="item.id">
							</el-option>
						</el-select>					</el-form-item>
				</div>
				
				
				<!--一行2个选项两端对齐-->
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="到期日期" prop="">
						<el-date-picker
							v-model="searchParams.timeValue"
							:default-time="['00:00:00', '23:59:59']"
							type="daterange"
							size="small"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							@change="getList('restPage')">
						</el-date-picker>
					</el-form-item>
				
				
				</div>
				
				
				
				
				<!--	<div style="display: flex; justify-content: space-between">
						<el-form-item label="状态" prop="status" class="from-item-second">
							<el-radio-group v-model="ruleForm.status">
								<el-radio :label="1">启用</el-radio>
								<el-radio :label="0">禁用</el-radio>
							</el-radio-group>
						</el-form-item>
						
						<el-form-item label="图片" class="from-item-second">
							<UploadImage :limit="2" :url="url"></UploadImage>
						</el-form-item>
					</div>-->
			</el-form>
			
			<div slot="footer" class="dialog-footer">
				<el-button class="mgr-20" size="mini" @click="handleCancel">取 消</el-button>
				<el-button size="mini" type="primary" @click="handleConfirm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import UploadImage from '@/components/UploadImage/UploadImage';

export default {
  name: "DialogAddOrder",
  components: {
    // UploadImage,
  },
  props: {
    width: { // 弹出框的宽度配置
      type: String,
      default: '640',
    },
    isActiveAddOrderDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssVars() {
      return {
        "--widthToOne" : this.width - 50 + "px",
        "--widthToTwo" : this.width / 2 - 40+ "px",
      }
    }
  },
  data () {
    return {
      searchParams: {},
      listLoading: false,
      url: [],
      options: [{
        value: 'test',
        id: '1'
      }],
      ruleForm: {
        testInput: '',
        testSelect: '',
        status: 1
      },
      rules: {
        testInput: [
          { required: true, message: 'testInput', trigger: 'blur' }
        ],
        testSelect: [
          { required: true, message: 'testSelect', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    handleSelect () {

    },
    handleConfirm (ruleForm) {
      this.submitForm(ruleForm)
    },
    handleCancel (e) {
      this.resetForm('ruleForm')
      this.$emit('update:isActiveAddOrderDialog', false)
    },
    submitForm (formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 出发test页面更新列表
          this.$emit('getList')
          this.$emit('update:isActiveAddOrderDialog', false)
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
	/*竖杠+后面文本的样式*/
	.required {
		margin-top: 2px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required+span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	/deep/ .el-form-item__label {
		padding-bottom: 0;
	}
	
	/deep/ .el-form-item__content {
		width: var(--widthToTwo);
	}
	
	.from-item-second {
		margin-left: 0;
	}
	
	/deep/ .from-item-third {
		.el-form-item__content {
			width: var(--widthToOne);
		}
		.el-select{
			width: var(--widthToOne) !important;
		}
	}
	
	/deep/ .el-cascader {
		width: var(--widthToTwo);
	}
	
	/deep/ .el-select {
		width: var(--widthToTwo) !important;
	}
	
	/deep/ .el-date-editor {
		width: var(--widthToTwo) !important;
	}
	
	/*select 宽度配置*/
	/deep/ .el-select {
		width: 85px;
	}
</style>
