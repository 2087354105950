<template>
	<div class="gg-container" v-loading.lock="fullscreenLoading" style="overflow: visible ;padding: 0;position:relative;padding:0 15px;box-sizing:border-box">
		<!-- <el-button type="danger" @click="closeDrawer"
							 style="position: absolute; display: flex; justify-content: center; align-items: center; border: 0;
							 border-radius: 4px 0 0 4px; top: 140px; left: -40px;width: 40px; height: 40px;">
			<i class="iconfont icon-cha" style="font-size: 24px"></i>
		</el-button> -->
										
		<!-- 问诊记录 -->
	
		<div class="information" @scroll="scrollLoad($event)" style="overflow:scroll;height: 690px;">
			<div class="" v-for="(item,index) of chatList" :key="index">
				<div class="msg-left" v-if="item.from_type==2 && item.message.type=='TEXT'">
					<div class="msg-img">
						<img :src="dataInfo.doctor_pic" style="width: 30px;">
					</div>
					<div class="msg-text">
						<span style="font-size: 12px;color: #777;">{{dataInfo.doctor_name}}</span>
						<div class="msg-text-b">
							{{item.message.msg}}
						</div>
					</div>
				</div>
				<div class="msg-left" v-if="item.message.type=='PRESCRIPTION'">
					<div class="msg-img">
						<img :src="dataInfo.doctor_pic" style="width: 30px;">
					</div>
					<div class="msg-text">
						<div class="msg-text-b">
							已开具处方
						</div>
					</div>
				</div>
				<div class="msg-left" v-if="item.message.type=='VIDEO_CHAT'">
					<div class="msg-img">
						<img :src="dataInfo.doctor_pic" style="width: 30px;">
					</div>
					<div class="msg-text">
						<div class="msg-text-b">
							发起视频通话
						</div>
					</div>
				</div>
				<div class="msg-left" v-if="item.message.type=='EMR'">
					<div class="msg-img">
						<img :src="dataInfo.doctor_pic" style="width: 30px;">
					</div>
					<div class="msg-text">
						<div class="msg-text-b">
							开具电子病历【病情描述:{{item.message.emr.desc}}。治疗意见:{{item.message.emr.advices}}】
						</div>
					</div>
				</div>
				
				<div class="msg-left" v-if="item.message.type=='MSGTIPS'">
					<div class="msg-img">
						<img src="https://pic.hngegejk.cn/ggjk_16484353953798" style="width: 30px;">
					</div>
					<div class="msg-text">
						<span style="font-size: 12px;color: #777;">系统消息</span>
						<div class="msg-text-b">
							{{item.message.msg}}
						</div>
					</div>
				</div>
				<div class="msg-right" v-if="item.from_type==3 && item.message.type=='TEXT'">
					<div class="msg-text">
						<span style="font-size: 12px;color: #777;">{{dataInfo.pat_name}}</span>
						<div class="msg-text-b">
							{{item.message.msg}}
						</div>
					</div>
					<div class="msg-img">
						<img :src="dataInfo.pat_pic" style="width: 30px;">
					</div>
				</div>
			
			</div>
		</div>
		
	</div>
</template>

<script>
import {
	getDiagChatsApi
} from '@/api/ggkq';
import {
  getPresDetail
} from '@/api/informationSearch';

export default {
  name: "diagChats",
  props: {
    id: {
      type: [Number, String]
    },
    type: {
      type: String,
      default: 'add'
    },
    doctorType: {
      type: [Number, String],
      default: 1
    },
    status: {
      type: [Number, String],
      default: 1
    },
    info: {
      type: Object
    },
    diag_id: {
      type: [String, Number]
    },


  },
  computed: {},
  async mounted () {
    await this.getInterviewRecord(this.diag_id, '1')
  },
  filters: {
    
  },
  data () {
    return {
      superviseType: [{
        value: '1',
        label: '图文'
      }, {
        value: '2',
        label: '视频'
      }],
      fullscreenLoading: false,
      activeName: 'first',
      prescriptionDetail: {}, //电子处方
      chatList: [], //问诊记录列表
      // 图片遮罩相关
      seen: false,
      current: null,
      isPicIdx: null,
      // 默认图片层级
      zIndex: 2000,
      presInfo: {},


      chatListLoading: false,
      pages: {
        page: 1,
        page_size: 15,
        total: ''
      },
      chatListFalse: false,
      options: [],
      value: ''
    }
  },
  methods: {

    scrollLoad (e) {
      if (e.srcElement.offsetHeight + e.srcElement.scrollTop >= e.srcElement.scrollHeight) {
        this.$set(this.pages, 'page', this.pages.page + 1)
        this.getInterviewRecord(this.diag_id, '2')
      }

    },
    //获取问诊记录
    getInterviewRecord (id, type) {
      if (this.chatListFalse) {
        return
      }
	  this.fullscreenLoading = true
      console.log(7777777)
      let params = {
        id: id,
        page: this.pages.page,
        page_size: this.pages.page_size
      }
      getDiagChatsApi(params).then(res => {
		this.fullscreenLoading = false
        if (res.code == 200) {
			console.log(11111111111,res)
          if (type == '1') {
			console.log(222,res)
            this.dataInfo = res.data
            this.chatList = res.data.list.reverse()
          } else {
            this.chatList = [...this.chatList, ...res.data.list.reverse()]
          }
          console.log('this.chatList', this.chatList)
        }
        if (!res.data.list.length) {
          this.chatListFalse = true
        }
      })
    },
   
    handleClick (tab, event) {
      console.log(tab, event);
    },
    enter (index = null) {
      this.seen = true;
      this.current = index;
      this.isPicIdx = index
    },
    leave () {
      this.seen = false;
      this.current = null;
      this.isPicIdx = null;
    },
    under (pics, refPic = 'pic') {
      if (pics.length > 0) {
        this.zIndex = 9999
        this.$refs[refPic].showViewer = true
      }
    },
    closeDrawer () {
      console.log(123)
      this.$emit('closeDrawer', false)
    }
  },
}
</script>

<style scoped lang="scss">
	.gg-container {
		box-sizing: border-box;
		margin: 0;
	}
	
	::v-deep.tabs-container {
		.el-tabs__header {
			padding: 0;
		}
	}
	
	/deep/ .el-tab-pane {
		min-height: 800px;
		overflow: visible;
	}
	
	.margin-t-10 {
		margin-top: 30px;
	}
	
	/* 蓝色方块 */
	.required {
		margin-top: 2px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required + span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	
	/*说明文字样式*/
	.title-pre-l {
		width: 120px;
		color: #777
	}
	
	/*鼠标滑动,详情颜色改变*/
	.is-hover {
		color: #333;
	}
	
	.is-hover:hover {
		color: #4ebafa
	}
	
	/*问诊记录*/
	.msg-img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		overflow: hidden;
	}
	
	.information {
		.msg-left {
			display: flex;
			margin-bottom: 10px;
			
			.msg-text {
				font-size: 12px;
				margin-left: 10px;
				
				.msg-text-b {
					margin-top: 5px;
					padding: 10px;
					background-color: #f7f7f7;
					border-radius: 8px;
					max-width: 350px;
					word-break: break-word
				}
			}
			
		}
		
		.msg-right {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 10px;
			
			.msg-text {
				font-size: 12px;
				margin-right: 10px;
				text-align: right;
				
				.msg-text-b {
					margin-top: 5px;
					padding: 10px;
					background-color: #f7f7f7;
					border-radius: 8px;
					max-width: 350px;
					word-break: break-word
				}
			}
		}
	}
	
	
	/*栅格文本居中*/
	.relation-container {
		display: flex;
		/*justify-content:center;!*主轴上居中*!*/
		align-items: center;
		/*侧轴上居中*/
	}
	
	.number-align {
		padding-top: 4px;
	}
	
	/*栅格边框*/
	.el-row {
		
		/*margin-bottom: 20px;*/
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.el-col {
		border-radius: 4px;
	}
	
	/deep/ .el-tab-pane {
		overflow: auto;
	}
	
	/*测试排版使用颜色*/
	/*.bg-purple-dark {*/
	/*	background: #99a9bf;*/
	/*}*/
	
	/*.bg-purple {*/
	/*	background: #d3dce6;*/
	/*}*/
	
	/*.bg-purple-light {*/
	/*	background: #e5e9f2;*/
	/*}*/
	
	.grid-content {
		border-radius: 4px;
		min-height: 30px;
	}
	
	
	.follow-up-container {
		margin-top: 20px;
		display: flex;
		align-items: center;
		
		.follow-up-select-wrapper {
			margin-left: 30px;
			margin-right: 20px;
			font-weight: bolder;
			color: #777;
		}
	}
	
	.follow-up-time {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: left;
		
		.title {
			width: 90px;
			font-weight: bolder;
			color: #777;
		}
		
		.content {
		
		}
	}
	
	.follow-up-content {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: left;
		
		.title {
			width: 90px;
			flex-shrink: 0;
			height: 100px;
			font-weight: bolder;
			color: #777;
		}
		
		.content {
			display: flex;
			justify-content: left;
			flex-direction: column;
			height: 100px;
			
			.up {
			
			
			}
			
			.down {
			
			}
		}
	}
</style>
