import request from '@/utils/request'

// 信息查询 - 处方流转 - 获取处方流转列表
export function getPresDeliveryList (params) {
  return request({
    url: '/admin/presDelivery/index',
    method: 'GET',
    params
  })
}

// 信息查询 - 处方流转 - 删除处方流转 Query 参数(delivery_id=处方流转id)
export function delPresDelivery (id) {
  return request({
    url: '/admin/presDelivery/delete',
    method: 'GET',
    params: {
      'delivery_id': id
    }
  })
}

// 信息管理 - 评论管理 - 获取评论列表
export function getAppraiseList (params) {
  return request({
    url: '/admin/appraise/index',
    method: 'GET',
    params
  })
}

// 信息管理 - 评论管理 -  禁用/启用评论
export function putAppraiseExamine (id, status) {
  return request({
    url: `/admin/appraise/examine/${id}`,
    method: 'GET',
    params:{
      status
    }
  })
}

// 信息管理 - 评论管理 - 删除评论
export function deleteAppraiseByIds (id) {
  return request({
    url: '/admin/appraise/delete',
    method: 'GET',
    params: {
      'appraise_id': id
    }
  })
}

// 信息管理 - 评论管理 - 批量删除评论
export function deleteAppraises (ids=[]) {
  return request({
    url: '/admin/appraise/deleteIds',
    method: 'GET',
    params:{
      ids
    }
  })
}


// 信息管理 - 获取机构列表
export function getSignOrganList (params) {
  return request({
    url: '/admin/signOrgan/index',
    method: 'GET',
    params
  })
}

// 信息管理 - 处方管理 - 获取处方列表
export function getPresList (params) {
  return request({
    url: '/admin/pres/index',
    method: 'GET',
    params
  })
}

// 信息管理 - 处方管理 - 获取处方详情 (pres_id)
export function getPresDetail (params) {
  return request({
    url: '/admin/pres/show/',
    method: 'GET',
    params
  })
}

// 信息管理 - 处方管理 - 删除处方 (pres_id)
export function delPresById (id) {
  return request({
    url: '/admin/pres/delete',
    method: 'GET',
    params:{
      pres_id: id
    }
  })
}

// 信息管理 - 诊疗过程 - 诊疗过程(问诊信息)列表
export function getTherapy (params) {
  return request({
    url: '/admin/therapy/index',
    method: 'GET',
    params
  })
}

// 信息管理 - 诊疗过程 - 诊疗过程(问诊信息)详情 (diag_id)
export function getTherapyDetail (params) {
  return request({
    url: '/admin/therapy/show',
    method: 'GET',
    params
  })
}

// 信息管理 - 诊疗过程 - 诊疗过程(问诊信息)沟通记录 (diag_id)
///admin/therapy/chat
export function getTherapyChat (id) {
  return request({
    url: '/admin/therapy/chat',
    method: 'GET',
    params:{
      diag_id: id
    }
  })
}

// 信息管理 - 诊疗过程 - 删除诊疗过程(问诊信息) (diag_id)
export function delTherapyById (id) {
  return request({
    url: '/admin/therapy/delete',
    method: 'GET',
    params: {
      diag_id: id
    }
  })
}

// 信息管理 - 诊疗过程 - 批量上传监管
export function putTherapyBatchSupervise (params) {
  return request({
    url: '/admin/therapy/batchSupervise',
    method: 'PUT',
    params
  })
}


// 医院监管 - 流转监管 - 批量上传监管
export function putPresDeliveryBatchSupervise (params) {
  return request({
    url: '/admin/presDelivery/batchSupervise',
    method: 'PUT',
    params
  })
}
