<template>
	<div class="gg-container">
		<el-button type="danger" @click="closeDrawer"
							 style="position: absolute; display: flex; justify-content: center; align-items: center; border: 0;
							 border-radius: 4px 0 0 4px; top: 140px; left: -40px;width: 40px; height: 40px;">
			<i class="iconfont icon-cha" style="font-size: 24px"></i>
		</el-button>
		<!--		<div class="caption-container">
					<div class="caption-title">
						<div class="required"></div>
						<span>客户详情</span></div>
				</div>-->

		<el-tabs v-model="activeName" @tab-click="handleClick">

			<el-tab-pane label="客户跟进" name="first">
				<div style="margin-top: 5px"></div>
				<div style="width: 1260px; overflow-y: scroll;" class="scrollBox minHeight">

					<div style="display: flex">

						<div style="width: 500px;">
							<div class="required"></div>
							<span>历史跟进</span>
							<div style="margin-top: 10px"></div>
							<el-table
								:data="historyVisitTableData"
								height="220"
								style="width: 100%; overflow-y: scroll; height: 220px; color: #000" class="scrollBox"
							>
								<el-table-column
									label="次数"
									align="center"
									width="50">
									<template slot-scope="scope">
										<div>{{historyVisitTableData.length - scope.$index}}</div>
									</template>
								</el-table-column>
								<el-table-column
									prop="created_at"
									label="跟进日期"
									align="center"
									width="130"
								>
								</el-table-column>

								<el-table-column
									prop="remark"
									label="回访摘要"
									show-overflow-tooltip
								>
								</el-table-column>
								<el-table-column
									prop="visit_level"
									label="级别"
									align="center"
									width="55">
								</el-table-column>
								<el-table-column
									prop="staff_name"
									label="客服"
									align="center"
									width="55">
								</el-table-column>
							</el-table>
							<!----------------分页---------------->
							<!--	<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
														@pagination="getList"/>-->

						</div>

						<div style="margin-left: 20px"></div>
						<div style="width: 600px;">

							<div style="display: flex; justify-content: space-between">
								<div>
									<div class="required"></div>
									<span>本次跟进</span>
								</div>
								<div>
									<div style="margin-left: auto; display: flex; justify-content: space-around">
										<div class="search-container-fn-input">
											<el-button
												size="mini"
												type="info"
												style="padding: 5px 6px 5px 6px"
												@click="handleRefreshFollow('formFollow')">
												<i class="el-icon-refresh" style="font-size: 12px"/>
												<span style="margin-left: 5px;font-size: 12px">重置本次跟进</span>
											</el-button>
										</div>

										<!--对应第三方图标的button样式-->
										<div class="search-container-fn-input">
											<el-button size="mini" type="primary" icon="el-icon-plus"
																 style="padding: 5px 6px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB; font-size: 12px"
																 @click="handleEditFollow('formFollow')">保存本次跟进
											</el-button>
										</div>

									</div>

								</div>
							</div>

							<div style="margin-top: 5px"></div>
							<div style="color:#000;">
								<el-form ref="formFollow" :model="ruleFormFollow" :rules="ruleFollow" size="mini" label-width="80px">
									<!--<el-form-item label="订单说明">-->
									<!--	<el-input :rows="2" type="textarea" v-model="ruleForm.order_info" disabled></el-input>-->
									<!--</el-form-item>-->

									<!--<el-form-item label="摘要提示">-->
									<!--	<el-input v-model="ruleFormFollow.visit_name" type="textarea" :rows="1" disabled></el-input>-->
									<!--</el-form-item>-->

									<el-form-item label="到期日期" prop="visit_level" label-width="80px" style="color: #000">
										<el-date-picker
											v-model="ruleFormFollow.expired_at"
											type="datetime"
											size="mini"
											value-format="yyyy-MM-dd HH:mm:ss"
											placeholder="选择日期时间"
											style="color: #000"
										>
										</el-date-picker>
									</el-form-item>

									<el-form-item label="客户级别" prop="visit_level_id">
										<el-select
											style="width:200px; color: #000"
											v-model="ruleFormFollow.visit_level_id"
											placeholder=""
											size="mini"
											clearable
											@change="getChangeCustomerLevel"
										>
											<template slot="prefix">
												<i class="el-icon-search" style="width: 25px; line-height: 32px;color: #000"></i>
											</template>
											<el-option
												v-for="item in customerLevelOptions"
												:key="item.id"
												:label="item.short_name"
												:value="item.id"
												style="color: #000"
											>
											</el-option>
										</el-select>
									</el-form-item>

									<el-form-item label="回访摘要" type="textarea" prop="remark" style="color: #000">
										<el-input type="textarea" :rows="1" v-model="ruleFormFollow.remark" style="color: #000"></el-input>
									</el-form-item>

									<el-form-item label="下次日期" prop="next_at" style="color: #000">

										<div style="display: flex; color: #000">
											<el-date-picker type="date" placeholder="选择日期" v-model="selectDate"
																			style="width: 100%;" value-format="yyyy-MM-dd"
																			:picker-options="pickerOptions"
																			@change="handleChangeDate"></el-date-picker>
											<!--	<div style="margin-left: 10px"></div>-->
											<!--<el-time-picker placeholder="选择时间" value-format="HH:mm:ss" v-model="selectTime" style="width: 100%;" @change="handleChangeTime"/>-->
										</div>


									</el-form-item>

									<el-form-item label="下次内容" prop="next_content" style="color: #000">
										<el-input v-model="ruleFormFollow.next_content" type="textarea" :rows="1" style="color: #000"></el-input>
									</el-form-item>
								</el-form>
							</div>
						</div>
					</div>


					<el-form :model="ruleForm" :rules="rules" size="mini" ref="ruleForm">
				<!--		<div class="required"></div>
						<span>客户标签</span>
						<div style="margin-top: 5px"></div>
						<el-form-item label="">
							<el-checkbox-group v-model="ruleForm.member_tag">
								<el-checkbox
									@change="handleCustomerLabelChange(index,item)"
									v-for="(item, index) in customerTag"
									:key="item"
									:label="item">{{item}}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>-->
						<div style="display: flex; justify-content: space-between; margin-top: 	0px">
							<div>
								<div class="required"></div>
								<span>客户档案信息</span></div>

							<!--<div class="search-container-fn-input" style="margin-right: 220px">-->
							<!--	<el-button size="mini" type="primary" icon="el-icon-plus"-->
							<!--						 style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;-->
              <!--                background-color: #2362FB; border-color: #2362FB"-->
							<!--						 @click="handleEditCustomerInfo('ruleForm')">保存修改-->
							<!--	</el-button>-->

							<!--</div>-->
						</div>

						<div style="display: flex; margin-top: 5px">
							<el-form-item label="姓名" prop="real_name" label-width="50px">
								<el-input style="width: 130px" v-model="ruleForm.name" autocomplete="off" clearable
                          :disabled="userType != 1"
													placeholder=""></el-input>
							</el-form-item>

							<el-form-item label="年龄" prop="age" label-width="60px">
								<el-input style="width: 60px" v-model.trim="ruleForm.age" autocomplete="off" clearable
													placeholder=""></el-input>
							</el-form-item>

							<el-form-item label="性别" prop="gender" label-width="60px">
								<el-select style="width: 80px" v-model="ruleForm.gender"
													 filterable
													 placeholder=""
													 @change="handleSelect">
									<el-option
										v-for="item in genderOptions"
										:key="item.id"
										:label="item.value"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="微信" prop="is_wx" label-width="50px">
								<el-select style="width: 60px" v-model="ruleForm.is_wx"
													 filterable
													 placeholder=""
													 @change="handleSelect">
									<el-option
										v-for="item in isWxOptions"
										:key="item.id"
										:label="item.value"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="渠道来源" prop="channel" label-width="80px" style="color: #000">
								<el-select v-model="ruleForm.from_id"
													 disabled
													 filterable
													 placeholder=""
													 @change="handleSelect"
													 style="color: #000"
								>
									<el-option
										style="width: 100px; color: #000"
										disabled
										v-for="item in channelFromOptions"
										:key="item.id"
										:label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="状态" prop="buy_status" label-width="60px">
								<el-select
									style="width: 100px"
									v-model="ruleForm.buy_status"
									filterable
									placeholder=""
									@change="handleSelect"
									disabled
								>
									<el-option
										v-for="item in buyStatusOptions"
										:key="item.id"
										:label="item.value"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="级别" prop="visit_level" label-width="50px">
								<el-select
									style="width: 100px"
									v-model="ruleForm.visit_level"
									filterable
									placeholder=""
									disabled
									@change="handleSelect">
									<el-option
										v-for="item in customerLevelOptions"
										:key="item.id"
										:label="item.short_name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>

							<!--<el-form-item label="到期日期" prop="visit_level" label-width="80px">-->
							<!--	<el-date-picker-->
							<!--		v-model="ruleForm.expired_at"-->
							<!--		type="datetime"-->
							<!--		size="mini"-->
							<!--		value-format="yyyy-MM-dd HH:mm:ss"-->
							<!--		placeholder="选择日期时间">-->
							<!--	</el-date-picker>-->
							<!--</el-form-item>-->


						</div>

						<div style="display: flex; flex-direction: row">
							<div style="margin-right: 10px; display: flex">
								<el-form-item label="移动电话" prop="mobile" label-width="78px">
									<el-input style="width: 130px" disabled v-model.trim="ruleForm.mobile" autocomplete="off" clearable
														placeholder=""></el-input>
									<div>{{addressDetails}}</div>
								</el-form-item>

								<el-form-item label="" label-width="8px">
									<el-button type="primary" icon="el-icon-phone" circle
														 @click="handleCall( ruleForm.contract_mobile[0].mobile)"></el-button>
								</el-form-item>
								<el-form-item label="" label-width="8px">
									<el-button type="primary" icon="el-icon-s-comment" circle
														 @click="handleMessage( ruleForm.contract_mobile[0].mobile)"></el-button>
								</el-form-item>
								<el-form-item label="关系" prop="" label-width="58px">
									<el-input  style="width: 80px" :disabled="true" v-model="title01" autocomplete="off" clearable></el-input>
								</el-form-item>

							</div>

							<div style="margin-right: 10px; display: flex">
								<el-form-item label="其它电话" prop="" label-width="78px">
									<el-input style="width: 130px" v-model.trim="ruleForm.contract_mobile[1].mobile" autocomplete="off" clearable
														placeholder=""></el-input>
								</el-form-item>
								<el-form-item label="" label-width="8px">
									<div>
										<el-button type="primary" icon="el-icon-phone" circle
															 @click="handleCall( ruleForm.contract_mobile[1].mobile)"></el-button>
									</div>
								</el-form-item>
								<el-form-item label="" label-width="8px">
									<el-button type="primary" icon="el-icon-s-comment" circle
														 @click="handleMessage( ruleForm.contract_mobile[1].mobile)"></el-button>
								</el-form-item>
								<el-form-item label="关系" prop="testInput" label-width="58px">
									<el-select style="width: 80px" v-model="ruleForm.contract_mobile[1].type"
														 filterable
														 placeholder=""
														 @change="handleSelect">
										<el-option
											v-for="item in relationOptions"
											:key="item.type"
											:label="item.value"
											:value="item.type">
										</el-option>
									</el-select>
								</el-form-item>
							</div>

							<div style="display: flex; flex-direction: row">
								<el-form-item label="固定电话" prop="" label-width="78px">
									<el-input style="width: 130px" v-model.trim="ruleForm.contract_mobile[2].mobile" autocomplete="off" clearable
														placeholder=""></el-input>
								</el-form-item>
								<el-form-item label="" label-width="8px">
									<div>
										<el-button type="primary" icon="el-icon-phone" circle
															 @click="handleCall( ruleForm.contract_mobile[2].mobile)"></el-button>
									</div>
								</el-form-item>
								<el-form-item label="" label-width="8px">
									<el-button type="primary" icon="el-icon-s-comment" circle
														 @click="handleMessage( ruleForm.contract_mobile[2].mobile)"></el-button>
								</el-form-item>
								<el-form-item label="关系" prop="testInput" label-width="58px">
									<el-select style="width: 80px" v-model="ruleForm.contract_mobile[2].type"
														 filterable
														 placeholder=""
														 @change="handleSelect">
										<el-option
											v-for="item in relationOptions"
											:key="item.type"
											:label="item.value"
											:value="item.type">
										</el-option>
									</el-select>
								</el-form-item>

							</div>

						</div>
						<div>
							<el-form-item label="客户说明" prop="remark" label-width="70px">
								<el-input style="width: 1100px" type="textarea" :rows="2" v-model.trim="ruleForm.remark"
													autocomplete="off" clearable
													placeholder=""></el-input>
							</el-form-item>
						</div>

						<!--客户标签-->
						<el-form-item label="客户标签" label-width="70px" v-if="ruleForm.tag_status == 1">
							<el-checkbox-group v-model="ruleForm.member_tag">
								<el-checkbox
									@change="handleCustomerLabelChange(index,item)"
									v-for="(item, index) in customerTag"
									:key="item"
									:label="item">{{item}}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>


						<div style="display: flex;">
							<el-form-item label="通讯地址" prop="address" label-width="70px">
								<el-cascader
									style="width: 280px"
									:options="options"
									v-model="ruleForm.address"
									@change="handleAddressChange"></el-cascader>
							</el-form-item>

							<el-form-item label="详细地址" prop="address_details" label-width="78px">
								<el-input style="width: 742px" type="textarea" :rows="1" v-model.trim="ruleForm.address_details" autocomplete="off" clearable
													placeholder=""></el-input>
							</el-form-item>
						</div>


					</el-form>

				</div>
			</el-tab-pane>


			<el-tab-pane label="客户订单" name="third">

				<div style="display: flex; justify-content: space-between">
					<div>
						<div class="required"></div>
						<span>订单记录</span></div>


					<!--<el-button size="mini" type="primary" icon="el-icon-plus"-->
					<!--					 style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;-->
					<!--                    background-color: #2362FB; border-color: #2362FB"-->
					<!--					 @click="handleAddOrder">创建订单-->
					<!--</el-button>-->
				</div>
				<div style="margin-top: 10px"></div>
				<!----------------表格---------------->

				<div style="width: 100%; overflow-y: scroll; height: 720px" class="scrollBox">
					<el-table
						border
						size="mini"
						v-loading="listLoading"
						:header-cell-style="{'text-align':'center'}"
						:data="customerOrderTableData"
						style="width: 100%;z-index:0">
						<el-table-column
							label="索引"
							type="index"
							align="center"
							width="50">
						</el-table-column>
						<el-table-column
							label="下单时间"
							prop="created_at"
							align="center"
							width="180">
						</el-table-column>
						<el-table-column
							prop="order_sn"
							label="订单编号"
							align="center">

							<template slot-scope="{row}">
								<a style="color: #2632fb" @click="handleOrderDetail(row.order_id)">{{row.order_sn}}</a>
							</template>
						</el-table-column>
						<el-table-column
							prop="actual_amount"
							label="订单金额"
							align="center">
						</el-table-column>
						<el-table-column
							prop="use_day"
							label="可用天数"
							align="center">
						</el-table-column>
						<el-table-column
							prop="order_status_name"
							label="订单状态"
							align="center">
						</el-table-column>

						<!--					<el-table-column align="center" width="180px" label="操作">
												<template slot-scope="scope">
													<el-button size="mini" type="primary" icon="el-icon-edit"
																		 style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;
																					background-color: #2362FB; border-color: #2362FB"
																		 @click="handleLogisticsDetail(scope.$index, scope.row)">查看物流
													</el-button>
													&lt;!&ndash;<el-button size="mini" type="danger" icon="el-icon-delete"&ndash;&gt;
													&lt;!&ndash;					 style="padding: 7px 8px;margin-left: 5px;"&ndash;&gt;
													&lt;!&ndash;					 @click="handleTable02(scope.$index, scope.row)">删除&ndash;&gt;
													&lt;!&ndash;</el-button>&ndash;&gt;
												</template>
											</el-table-column>-->
					</el-table>
				</div>
				<!----------------分页---------------->
				<!--<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
										@pagination="getList"/>-->

			</el-tab-pane>


			<el-tab-pane label="问诊记录" name="forth">

				<div style="display: flex; justify-content: space-between">
					<div>
						<div class="required"></div>
						<span>问诊记录</span></div>


					<!--<el-button size="mini" type="primary" icon="el-icon-plus"-->
					<!--					 style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;-->
					<!--                    background-color: #2362FB; border-color: #2362FB"-->
					<!--					 @click="handleAddOrder">创建订单-->
					<!--</el-button>-->
				</div>
				<div style="margin-top: 10px"></div>
				<!----------------表格---------------->

				<div style="width: 100%; overflow-y: scroll; height: 720px" class="scrollBox">
					<el-table
						border
						v-loading="listLoading"
						:header-cell-style="{'text-align':'center'}"
						:data="dialogRecordTableData"
						style="width: 100%;z-index:0">
						<el-table-column
							label="索引"
							type="index"
							align="center"
							width="80">
						</el-table-column>
						<el-table-column
							label="问诊编号"
							prop="order_sn"
							align="center">
						</el-table-column>
						<el-table-column
							label="问诊类型"
							prop="order_sn"
							align="center">
							<template slot-scope="{row}">
								<el-tag type="primary">{{ row.type == 1 ? '图文问诊' : '视频问诊' }}</el-tag>
							</template>
						</el-table-column>
						<el-table-column
							prop="doctors_name"
							label="问诊医生"
							align="center">
						</el-table-column>
						<el-table-column
							prop="desc"
							label="主诉"
							align="center">
						</el-table-column>
						<el-table-column
							label="问诊状态"
							prop="order_status_name"
							align="center">
							<template slot-scope="{row}">
								<el-tag type="primary">{{ row.order_status_name }}</el-tag>
							</template>
						</el-table-column>
						<el-table-column
							label="电子病历"
							prop="order_status_name"
							align="center">
							<template slot-scope="{row}">
								<el-image
									v-if="row.emr_url"
									:z-index="9999"
									style="width: 50px; height: 50px"
									:src="row.emr_url"
									:preview-src-list="[row.emr_url]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column
							label="电子处方"
							prop="order_status_name"
							align="center">
							<template slot-scope="{row}">
								<el-image
									v-if="row.pres_url.length>0"
									:z-index="9999"
									style="width: 50px; height: 50px"
									:src="row.pres_url[0]"
									:preview-src-list="row.pres_url">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column
							label="问诊视频"
							prop="order_status_name"
							align="center">
							<template slot-scope="{row}">
								<div v-if="row.video_cloud_file&&row.video_cloud_file.length>0">
									<el-link :underline="false" type="primary" @click="handleVideo(row)">查看视频</el-link>
								</div>
								<div v-else>无</div>

							</template>
						</el-table-column>
						<el-table-column
							label="问诊记录"
							prop="created_at"
							align="center"
							width="160">
							<template slot-scope="{row}">
								<el-link :underline="false" type="primary" @click="handleRecord(row)">查看记录</el-link>
							</template>
						</el-table-column>

						<!--						<el-table-column
													prop="use_day"
													label="可用天数"
													align="center">
												</el-table-column>-->

						<el-table-column
							label="创建时间"
							prop="created_at"
							align="center"
							width="160">
						</el-table-column>

						<!--					<el-table-column align="center" width="180px" label="操作">
												<template slot-scope="scope">
													<el-button size="mini" type="primary" icon="el-icon-edit"
																		 style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;
																					background-color: #2362FB; border-color: #2362FB"
																		 @click="handleLogisticsDetail(scope.$index, scope.row)">查看物流
													</el-button>
													&lt;!&ndash;<el-button size="mini" type="danger" icon="el-icon-delete"&ndash;&gt;
													&lt;!&ndash;					 style="padding: 7px 8px;margin-left: 5px;"&ndash;&gt;
													&lt;!&ndash;					 @click="handleTable02(scope.$index, scope.row)">删除&ndash;&gt;
													&lt;!&ndash;</el-button>&ndash;&gt;
												</template>
											</el-table-column>-->
					</el-table>
				</div>

			</el-tab-pane>

		</el-tabs>

		<el-drawer
			title="问诊记录"
			:append-to-body="true"
			:visible.sync="diagChatsVisiable">
			<diagChats
			v-if="diagChatsVisiable"
			:diag_id="diag_id">
			</diagChats>
   		</el-drawer>
		<el-dialog title="问诊视频" :visible.sync="diagVideoVisiable" :append-to-body="true">
			<div  v-for="(item,index) of videoList" :key="index" style="width:400px;margin:30px auto">
				<!-- <img src="../../../assets/video.png" style="width:96px;height:69px"> -->
				<Video :src="item" :second="3"  width="400" height="300" :autoplay="false" :controls="true" :showPlay="true"/>
				<!-- <el-image
					style="width: 100px; height: 100px"
					src="../../../assets/video.png"
					:fit="fit"></el-image> -->

			</div>
		</el-dialog>

		<!--查看订单详情弹出框-->
		<dialogOrderDetail :isActiveOrderDetailDialog.sync="isActiveOrderDetailDialog"
											 @getList="getList"
											 :orderId="orderId"
											 v-if="isActiveOrderDetailDialog"
											 width="1200"
		></dialogOrderDetail>

		<el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false"
		:modal="false">
      <span slot="title" style="border-bottom:2px solid #2362FB"
        >发送短信</span
      >
      <div class="row">
        <div style="width:80px;">名称：</div>
		<el-select v-model="sms_config_id" placeholder="请选择" size="mini">
    <el-option
      v-for="item in optionsMsg"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="_ok" size="mini">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
import {regionData,regionDataPlus, provinceAndCityDataPlus,provinceAndCityData, CodeToText} from 'element-china-area-data'

// import Pagination from '@/components/Pagination'

import Video from '@/components/media/Video'
import dialogAddOrder from './dialogAddOrder';
import dialogOrderDetail from './dialogOrderDetail';
import diagChats from './diagChats';
import moment from "moment";
import {
  getCustomerTag, getCustomerRecordDetail, getCustomerLevel, getCustomerOrderList, getSeeCustomerRecordDetail,
  postAddFollow, putEditCustomerDetail, getDialogRecord, postCallMobile, getChancelFrom
} from '@/api/ggkq';
import {
	sendMessage,
	smsOptions
} from "@/api/note";
import {mapGetters, mapState} from 'vuex';

export default {
  name: "DrawCustomerDetail",
  props: {
    info: {	// 传递过来的信息
      type: Object,
      default: () => {
        return {}
      },
    },
    height: {
      type: String,
      default: '600px',
    }
  },
  components: {
    // Pagination,
    dialogOrderDetail,
	diagChats
  },
  computed: {
    ...mapGetters(["agentId", "adminId", "userType"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
    }),
  },
  async created () {
    const that = this;
    that.styleChange.height = window.innerHeight + "px"; // 屏幕高度
    that.styleChange.width = window.innerWidth + 'px'; // 屏幕宽度
    that.styleHeight = (window.innerHeight - 120) + 'px'; // 屏幕宽度
    console.log("高度", that.styleChange.height);
    console.log("宽度", that.styleHeight)
	await this._smsOptions()
    await this._getChancelFrom()
    await this.handleGetCustomerTag()
    await this.handleGetCustomerLevel()
    await this.handleGetCustomerRecordDetail()
    // await this.handleGetSeeCustomerRecordDetail()
    // await this.handleGetCustomerOrderList()
    // await this.handleGetDialogRecord()
  },
  filters: {
    useStatusFormat: (status) => {
      let result = ''
      // 0 未用 1 在用 2 停用 3 预停
      switch (status) {
        case 0:
          result = '未用'
          break;
        case 1:
          result = '在用'
          break;
        case 2:
          result = '停用'
          break;
        case 3:
          result = '预停'
          break;
      }
      return result;
    }
  },
  data () {
    return {
		dialogVisible:false,
	  diag_id:0,
	  diagChatsVisiable:false,
	  diagVideoVisiable:false,
      styleHeight: '',
      styleChange: {
        height: '',
        width: '',
      },
      activeName: 'first',
      options: regionDataPlus,
      addressDetails:'',
      selectedOptions: [],
      customerTag: [],
      isActiveOrderDetailDialog: false,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      channelFromOptions: [],
      tableData: [],
      customerOrderTableData: [],
      historyVisitTableData: [],
      dialogRecordTableData: [],
      genderOptions: [{
        value: '男',
        id: 1
      }, {
        value: '女',
        id: 2
      }, {
        value: '其他',
        id: 3
      }],
      isWxOptions: [
        {
          value: '否',
          id: 0
        }, {
          value: '是',
          id: 1
        }
      ],
      buyStatusOptions: [
        {
          value: '未购',
          id: 1
        }, {
          value: '已购',
          id: 2
        }
      ],
      // 与本人关系 1 本人 2 父母 3 配偶 4 子女 5 亲戚 6 朋友 7 其他

      relationOptions: [
        // {
        //   value: '本人',
        //   type: 1
        // },

        {
          value: '父母',
          type: 2
        }
        , {
          value: '配偶',
          type: 3
        }
        , {
          value: '子女',
          type: 4
        }
        , {
          value: '亲戚',
          type: 5
        }
        , {
          value: '朋友',
          type: 6
        }
        , {
          value: '其他',
          type: 7
        }
      ],
      customerLevelOptions: [],
      goodsOptions: [],
      ruleForm: {
        real_name: '',
        gender: '',
        is_wx: '',
        from_id: '',
        channel: '',
        age: '',
        mobile: '',
        address: [],
        province_id: '',
        city_id: '',
        area_id: '',
        address_details: '',
        buy_status: '',
        visit_level: '',
        contract_mobile: [{
          mobile: "",
          type: 7,
          id: 0,
        }, {
          mobile: "",
          type: 7,
          id: 0,
        },
          {
            mobile: "",
            type: 7,
            id: 0,
          }],
        remark: '',
        goods_id: '',
        expired_at: '',
        member_tag: [],
      },
      member_tag: [],
      selectCustomerLevel: '',
      rules: {
        real_name: [
          { required: true, message: '姓名', trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '性别', trigger: 'blur' }
        ],
        age: [
          { required: true, message: '年龄', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号', trigger: 'blur' }
        ],
        buy_status: [
          { required: true, message: '购买状态', trigger: 'blur' }
        ],
        goods_id: [
          { required: true, message: '咨询商品', trigger: 'blur' }
        ],
        expired_at: [
          { required: true, message: '到期日期', trigger: 'blur' }
        ],
      },
      ruleFormFollow: {
        visit_level_id: '',
        visit_name: '',
        remark: '',
        next_at: '',
        next_content: '',
        selectDate: '',
        selectTime: '',
        expired_at: ''
      },
      ruleFollow: {
        visit_level_id: [
          { required: true, message: '回访级别', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '回访摘要', trigger: 'blur' }
        ],
        next_content: [
          { required: true, message: '填写内容', trigger: 'blur' }
        ],
        next_at: [
          { required: true, message: '选择时间', trigger: 'blur' }
        ],
        expired_at: [
          { required: true, message: '到期日期', trigger: 'blur' }
        ],
      },
      selectDate: '', /// 回访选择时间
			selectDateOrigin: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() // 选当前时间之前的时间
        },
      },
      selectTime: '',
      customerDetail: {
        mobile: '',
        contract_mobile: [
          {
            mobile: "",
            type: 7,
            id: 0,
          },
          {
            mobile: "",
            type: 7,
            id: 0,
          },
          {
            mobile: "",
            type: 7,
            id: 0,
          }
        ],
      },
	  videoList:[],
      orderId: '',
			title01: '本人',
			name: '',
			phone: '',
			sms_config_id:null,
			optionsMsg:[],
    }
  },
  methods: {
		    //短信模板选项
			_smsOptions() {
      smsOptions().then((response) => {
        let data = response;
        if (data.code == 200) {
          this.optionsMsg = data.data;
        }
      });
    },
	//发送短信
	handleMessage(row){
		console.log(row)
		this.dialogVisible = true
		this.phone = row
	},
	_ok(){
		if(this.sms_config_id == null) {
			this.$message({
				type: 'error',
				message: '请选择短信模板'
			})
			return
		}
		let data = {
			mobile:this.phone,
			sms_config_id:this.sms_config_id
		}
		sendMessage(data).then(res=>{
			if(res.code == 200) {
				this.$message({
					type: 'success',
					message: '发送成功'
				})
				this.dialogVisible = false
			}else{
				this.$message({
					type: 'error',
					message: res.msg
				})
			}
		})
	},
	handleRecord(row){
		this.diag_id = row.id
		this.diagChatsVisiable = true
	},
	handleVideo(row){
		this.videoList = row.video_cloud_file
		this.diagVideoVisiable = true
	},
    async _getChancelFrom () {
      var params = {
        c_type: 'from',
        status: 1
      }
      var result = await getChancelFrom(params)
      console.log(result)
      this.channelFromOptions = result.data
    },
    async handleGetCustomerTag () {
      let result = await getCustomerTag()
      console.log(result)
      result.data.forEach(item => {
        this.customerTag.push(item.name)
      })
    },
    async handleGetCustomerLevel () {
      var params = {
        con_id: this.info.contract_id,
        buy_status: this.ruleForm.buy_status,
      }
      let result = await getCustomerLevel(params)
      console.log(result)
      this.customerLevelOptions = result.data
      this.customerLevelOptions.forEach(item => {
        item.short_name = item.short_name + ' ' + item.name + ' ' + item.next_content
      })
    },
    async handleGetCustomerRecordDetail () {
      let result = await getCustomerRecordDetail(this.info.contract_id)
      // this.ruleForm.address = ["110000" ,"110100" , "110101"]
      this.historyVisitTableData = result.data.history_visit.reverse();
      console.log(result)
      switch (result.data.contract_mobile.length) {
        case 0:
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          break;
        case 1:
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          break;
        case 2:
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          break;
      }
      result.data.member_tag = (result.data.member_tag == null ? [] : result.data.member_tag)
      console.log(result)
      this.ruleForm = result.data
      this.ruleForm.expired_at = result.data.expired_at
      this.ruleFormFollow.expired_at = result.data.expired_at
      this.ruleForm.real_name = result.data.name
			if(result.data.city_id == 0) {
        this.ruleForm.address = [result.data.province_id.toString(), '']
			} else if (result.data.area_id == 0) {
        this.ruleForm.address = [result.data.province_id.toString(), result.data.city_id.toString(), '']
			} else {
        this.ruleForm.address = [result.data.province_id.toString(), result.data.city_id.toString(), result.data.area_id.toString()]
			}
			console.log(this.ruleForm.address)
			this.addressDetails = result.data.address_name;
     	// this.ruleForm.address = ['110000', '']
      if (!Array.isArray(this.ruleForm.member_tag)) {
        this.ruleForm.member_tag = []
      }
      if(result.data.age == null ) {
        this.ruleForm.age = 0
			}
      if(result.data.gender == null ) {
        this.ruleForm.gender = 3
      }
    },
    async handlePutEditCustomerDetail (id, data) {
      let result = await putEditCustomerDetail(id, data)
      console.log(result)
      return result
    },
    async handleGetSeeCustomerRecordDetail () {
      let result = await getSeeCustomerRecordDetail(this.info.contract_id)
      // let result = await getSeeCustomerRecordDetail(13536)
      console.log(result)

      switch (result.data.contract_mobile.length) {
        case 0:
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          break;
        case 1:
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          break;
        case 2:
          result.data.contract_mobile.push({
            mobile: "",
            type: 7,
            id: 0,
          })
          break;
      }
      this.customerDetail = result.data

    },
    async handleGetCustomerOrderList () {
      let result = await getCustomerOrderList(this.info.contract_id)
      // let result = await getCustomerOrderList(13114)
      console.log(result)
      this.customerOrderTableData = result.data
    },
    async handlePostAddFollow (id, data) {
      let result = await postAddFollow(id, data)
      return result
    },
    async handleGetDialogRecord () {
      // let result = await getDialogRecord(13613)
      let result = await getDialogRecord(this.info.contract_id)
      this.dialogRecordTableData = result.data
    },
    test () {
    },
    getList (type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      console.log('getList触发')
      //
      // this.listLoading = true
      // this.searchParams.page = this.listQuery.page
      // this.searchParams.page_size = this.listQuery.limit
      // this.test(this.searchParams).then(response => {
      //   let data = response
      //   if (data.code == 200) {
      //     let _data = data.data
      //     this.total = _data.total
      //     this.listQuery.limit = _data.per_page
      //     this.tableData = _data.list
      //   }
      //   this.listLoading = false
      // }).catch(error => {
      //   console.log(error);
      //   this.listLoading = false
      // })
    },
    async handleCall (mobile) {
      var id = this.info.contract_id
      var data = {
        mobile: mobile
      }
      var result = await postCallMobile(id, data)
      if (result.code == 200) {
        this.$alert('拨通中', '拨通中', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: ''
            });
          }
        });
      } else {
        this.$message({
          message: result.msg,
          type: 'error'
        })
        // this.$alert('拨通中', '拨通中', {
        //   confirmButtonText: '确定',
        //   customClass:"return-visit-message-box",
        //   callback: action => {
        //     this.$message({
        //       type: 'info',
        //       message: '挂断电话'
        //     });
        //   }
        // });

      }
    },
    handleCustomerLabelChange (index, item) {
      console.log('客户标签')
      console.log(index)
      console.log(item)
    },
    handleRefresh () {
    },
    handleEditCustomerInfo (formName) {
      this.submitForm(formName)
    },
    submitForm (formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 出发test页面更新列表
          let id = this.info.contract_id
					this.ruleForm.expired_at = this.ruleFormFollow.expired_at
          var data = this.ruleForm
          data.contact = []
          data.province_id = this.ruleForm.address[0]
          data.city_id = this.ruleForm.address[1]
          data.area_id = this.ruleForm.address[2]
					data.real_name = data.name
          this.ruleForm.contract_mobile.forEach(item => {
            if (item.mobile != '') {
              data.contact.push(item)
            }
          })
          var result = await this.handlePutEditCustomerDetail(id, data)
          if (result.code == 200) {
            this.$message({
              type: "success",
              message: '修改成功',
            });
            await this.handleGetCustomerRecordDetail()
          } else {
            this.$message({
              type: "error",
              message: result.msg,
            });
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    handleRefreshFollow (formName) {
      this.ruleFormFollow.visit_name = ''
      this.selectDate = ''
      this.selectTime = ''
      this.resetFormFollow(formName)
    },
    handleEditFollow (ruleForm) {
      this.submitFormFollow(ruleForm)
      this.handleEditCustomerInfo('ruleForm')
    },
    getChangeCustomerLevel () {
      this.customerLevelOptions.forEach(item => {
        if (item.id == this.ruleFormFollow.visit_level_id) {
          this.ruleFormFollow.next_content = item.name
          console.log(this.ruleFormFollow.next_content)
          // let interval_days = item.interval_days;
          let current_datetime = new Date()
          current_datetime.setDate(current_datetime.getDate() + item.interval_days)
          console.log(current_datetime.getTime());
          console.log(Date.now())
          var selectDateOrigin = current_datetime.getTime()
          this.pickerOptions =  {
            disabledDate(time) {
              return  time.getTime() > selectDateOrigin  ||  time.getTime() < Date.now() - 86400000// 选当前时间之前的时间
            }
          }
          let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate()
          this.selectDate = formatted_date;
          this.ruleFormFollow.next_at = this.selectDate
        }
      })
    },
    handleChangeDate () {
      console.log(this.selectDate)
      // if (this.selectDate != '' && this.selectTime!= '') {
      //   this.ruleFormFollow.next_at = this.selectDate + ' ' + this.selectTime
      // }
      if (this.selectDate != '') {
        this.ruleFormFollow.next_at = this.selectDate
      }
    },
    handleChangeTime () {
      console.log(this.selectTime)
      if (this.selectDate != '' && this.selectTime != '') {
        this.ruleFormFollow.next_at = this.selectDate + ' ' + this.selectTime
      }
    },
    submitFormFollow (formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 出发test页面更新列表
          let id = this.info.contract_id
          var data = {
            visit_level_id: this.ruleFormFollow.visit_level_id,
            remark: this.ruleFormFollow.remark,
            next_at: this.ruleFormFollow.next_at,
            next_content: this.ruleFormFollow.next_content,
          }
          var result = await this.handlePostAddFollow(id, data)
          if (result.code == 200) {
            this.$message({
              type: "success",
              message: '创建成功',
            });
            this.handleRefreshFollow('formFollow')
            await this.handleGetCustomerRecordDetail()
          } else {
            this.$message({
              type: "error",
              message: result.msg,
            });
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    resetFormFollow (formName) {
      this.$refs[formName].resetFields();
    },

    handleAddressChange (value) {
      console.log(value) // value值为区域码
      // 用CodeToText转换成中文
      if (value != undefined) {
        console.log(CodeToText[value[0]])
        console.log(CodeToText[value[1]])
        console.log(CodeToText[value[2]])
      }

    },
    // 关闭抽屉出发上一个页面数据
    closeDrawer () {
      console.log(123)
      this.$emit('closeDrawer', false)
    },
    handleSelect () {

    },

    async handleClick (tab, event) {
      // console.log(tab, event);
      console.log(tab.name);
      switch (tab.name) {
        case "first":
          await this.handleGetCustomerRecordDetail()
          break;
        case "second":
          await this.handleGetSeeCustomerRecordDetail()
          break;
        case "third":
          await this.handleGetCustomerOrderList()
          break;
        case "forth":
          await this.handleGetDialogRecord()
          break;

      }
    },
    handleAddOrder () {
      this.isActiveOrderDetailDialog = true
    },
    handlerExpiredAt() {
      console.log(this.ruleForm.expired_at)
		},
    handleOrderDetail (id) {
      console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
      console.log(id)
      this.orderId = id.toString()
      this.isActiveOrderDetailDialog = true
    },
  }
}
</script>

<style lang="scss">
	.el-message-box.return-visit-message-box {
		width: 355px;
		padding-bottom: 0;

		.el-message-box__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 10px;
			background: rgba(241, 243, 245, 1);

			.el-message-box__title {
				font-size: 16px;
				font-weight: 500;
				color: rgba(51, 51, 51, 1);
			}

			.el-message-box__headerbtn {
				position: unset;
			}
		}

		.el-message-box__content {
			padding-top: 20px;

			.content-title {
				font-size: 16px;
				font-weight: 500;
				color: rgba(51, 51, 51, 1);
				line-height: 22px;
				margin-bottom: 16px;
			}

			.content-message {
				font-size: 12px;
				font-weight: 400;
				color: #666666;
				line-height: 17px;
			}
		}

		.el-message-box__btns {
			padding: 20px 20px 20px;

			.el-button {
				padding: 9px 27px;
				background-color: #2632fb;
				border-color: #2632fb;
			}
		}
	}
</style>

<style scoped lang="scss">
	/*竖杠+后面文本的样式*/
	.required {
		margin-top: 3px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}

	.required + span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}

	/*caption-container样式*/
	.caption-container {
		.caption-title {
			font-size: 16px;
			color: #000;
			font-weight: bold;
			margin-top: 0;
		}

		.caption-wrapper {
			display: flex;
			justify-content: left;
			align-items: center;

			.caption-avatar {
			}

			.caption-info {
				display: flex;
				flex-direction: column;
				margin-left: 20px;

				.caption-info-up {
					height: 30px;
					font-size: 16px;
					font-weight: bold;
					color: #000;

					.name {
						margin-right: 15px;
					}
				}

				.caption-info-down {
					display: flex;
					justify-content: left;
					align-items: center;
					height: 25px;

					.gender {
						width: 39px;

						.nv {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 14px;
							height: 14px;
							background-color: #E03030;
							color: #fff;
						}

						.nan {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 14px;
							height: 14px;
							background-color: #2362fb;
							color: #fff;
						}
					}

					.intro {
						display: flex;
						justify-content: left;
						align-items: center;
						background-color: #f7f7f7;
						color: #777;
						font-size: 14px;

						.intro-icon {
							margin-left: 5px;
							margin-right: 5px;
							padding-top: 1px;
							font-size: 16px;
						}

						.intro-title {
							font-weight: bold;
						}
					}
				}
			}
		}
	}


	/*栅格布局*/
	.bg-purple {
		background: #d3dce6;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}


	/deep/ .from-item-third {
		.el-form-item__content {
			width: 600px;
		}

		.el-select {
			width: 600px !important;
			.el-input {
				color: #000 !important;
				.el-input__inner {
					color: #000 !important;
				}
			}
		}
	}

	/deep/ .el-input.is-disabled .el-input__inner{
		color: #000;
	}

	.scrollBox::-webkit-scrollbar {
		width: 5px;
	}

	.scrollBox::-webkit-scrollbar-thumb {
		border-radius: 20px;
		-webkit-box-shadow: inset 0 0 5px #fff;
		background: #D8D8D8;
	}

	.scrollBox::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px #fff;
		border-radius: 0;
		background: #fff;
	}


	.customer-info {
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		width: 1000px;

		.customer-info-item {
			flex: 1;
			display: flex;

			.item-title {
				font-size: 14px;
				font-weight: bolder;
				margin-right: 10px;
				color: #000;
			}

			.item-content {
				font-size: 14px;
				color: #000;
			}
		}
	}


	.line {
		margin-bottom: 20px;
		width: 100%;
		height: 1px;
		background-color: #eaeaea;
	}


	.el-message-box.visit-message-box {
		width: 1406px !important;

		.el-message-box__btns {
			.el-button {
				padding: 6px 10px;
			}

			.el-button--small {
				padding: 6px 10px;
			}

			.el-button--primary {
				background-color: #2632fb;
				border-color: #2632fb;
			}
		}

	}

	.minHeight {
		//height: var(--height) !important;
		height: 576px;
	}

	/*滚动条样式*/
	::-webkit-scrollbar {
		width: 7px;
		height: 5px;
		border-radius: 15px;
		-webkit-border-radius: 15px;
	}

	::-webkit-scrollbar-track-piece {
		background-color: #ffff;
		border-radius: 15px;
		-webkit-border-radius: 15px;
	}

	::-webkit-scrollbar-thumb:vertical {
		height: 5px;
		background-color: rgba(144, 147, 153, 0.5);
		border-radius: 15px;
		-webkit-border-radius: 15px;
	}

	::-webkit-scrollbar-thumb:horizontal {
		width: 7px;
		background-color: rgba(144, 147, 153, 0.5);
		border-radius: 15px;
		-webkit-border-radius: 15px;
	}

	.el-table {
		/deep/ .el-table__body-wrapper::-webkit-scrollbar {
			width: 10px; /*滚动条宽度*/
			height: 10px; /*滚动条高度*/
		}

		/*定义滚动条轨道 内阴影+圆角*/
		/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
			box-shadow: #efefef inset; /*滚动条的背景区域的内阴影*/
			border-radius: 10px; /*滚动条的背景区域的圆角*/
			background-color:  #efefef; /*滚动条的背景颜色*/
		}

		/*定义滑块 内阴影+圆角*/
		/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
			box-shadow:  rgba(144, 147, 153, 0.5) inset; /*滚动条的内阴影*/
			border-radius: 10px; /*滚动条的圆角*/
			background-color:  rgba(144, 147, 153, 0.5); /*滚动条的背景颜色*/
		}
	}


	.row{
    display: flex;
    align-items: center;
    margin-top:20px;
}

</style>


