<template>
	<div class="" style="position: relative">
		<el-upload
			:class="{hide:hideUpload}"
			:style="cssVars"
			:action="actionPath"
			:headers="header"
			list-type="picture-card"
			accept=".jpg,.jpeg,.png,.bmp"
			:on-preview="handlePictureCardPreview"
			:on-remove="handleRemove"
			:on-change="handleChange"
			:on-success="handleSuccess"
			:before-upload="beforeUpload"
			:file-list="fileList"
			:limit="limit">
			<!-- 图片边距 8px , 加载框 42px居中需要除2 , 所以偏移量 29 -->
			<div :style="{'position': 'absolute','top': `${10+(height-100)/2}px` , 'left':`-${width/2+29}px`}" v-loading="loading"></div>
			<i class="el-icon-plus"></i>
		</el-upload>
		<el-dialog :visible.sync="dialogVisible" :append-to-body="true">
			<img width="100%" :src="dialogImageUrl" alt="" style="display: block">
		</el-dialog>
	</div>
</template>

<script>
import {getToken} from "@/utils/auth";

export default {
  name: "UploadImage",
  props: {
    limit: {
      type: Number,
			default: 2, // 默认可以上传3张图片
		},
    width: {	// 默认图片选择框宽度
      type: String,
			default: '130'
		},
		height: { // 默认图片选择框高度
      type: String,
      default: '80'
		},
    url: {
      type: Array,
      default: () => { // 外部传入图片地址
        return []
        // return ['https://pic.hngegejk.cn/ggjk_16516288112051']
			},
			// ['https://pic.hngegejk.cn/ggjk_16516288112051','https://pic.hngegejk.cn/ggjk_16516288131259']
    },
	},
  computed: {
    cssVars() {	// 外部传入参数决定显示框大小
      return {
        "--width": this.width + 'px',
				"--height": this.height+ 'px',
      };
    },
	},
	watch: {
    url: {	// 监视外部传入的url,格式化未 up-upload 能够显示的格式, 只运行一次
      handler(newVal, oldVal) {
				if(this.onceWatchFlag) {
				  this.onceWatchFlag = false
          newVal.forEach(e => {
            var obj = {
              url: e,
              uid: e.substring(-9 -3)
            }
            this.fileList.push(obj)
          })
          console.log(this.fileList)
				}
      },
      immediate: true,
      deep: true,
		}
	},
  data () {
    var hideUpload = false
    if(this.limit <= this.url.length) {
       hideUpload = true
		}
    return {
      onceWatchFlag: true,	// 监视一次, 回显已经有的图片
      loading: false,
      header: {
        Authorization: "Bearer " + getToken(),
      },
      actionPath: "/admin/upload",
      hideUpload: hideUpload,	// 限制可以添加图片个数, 多了就隐藏
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrl: '',
			fileList: [],
		}
  },
  methods: {
    handleRemove (file, fileList) {
      console.log(file);
      console.log(fileList)
      var urlAddr = []
      fileList.forEach(item => {
        if (item.response && Object.keys(item.response).length > 0 ) {
          urlAddr.push(item.response.data)
        } else {
          urlAddr.push(item.url)
        }
      })
      this.$emit("on-success", urlAddr);
      this.hideUpload = fileList.length >= this.limit;
    },
    handleChange (file, fileList) {
      console.log(fileList)
			var urlAddr = []
      fileList.forEach(item => {
			  if (item.response && Object.keys(item.response).length > 0 ) {
			    urlAddr.push(item.response.data)
				} else {
          urlAddr.push(item.url)
				}
			})
      this.$emit("on-success", urlAddr);
      this.hideUpload = fileList.length >= this.limit;
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleSuccess (res, file) {
      this.loading = false
      // this.imageUrl = URL.createObjectURL(res.data);
			if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '上传成功!'
        })
        this.imageUrl = res.data;
			} else {
        this.$message({
          type: 'error',
          message: '上传失败!'
        })
			}
    },
    beforeUpload (file) {
      // const isJPG = file.type === 'image/jpeg/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      // return isJPG && isLt2M;
      this.loading = true
      return isLt2M;
    }
  }
}
</script>

<style scoped lang="scss">
	/deep/ .el-upload {
		width: var(--width);
		height: var(--height);
		border-radius: 0;
	}
	
	/deep/ .el-upload--picture-card {
		position: relative;
		
		.el-icon-plus {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}
	
	/deep/ .el-upload-list__item {
		width: var(--width);
		height: var(--height);
		border-radius: 0;
	}
	
	/deep/ .is-ready {
		width: var(--width);
		height: var(--height);
		border-radius: 0;
	}
	
	/deep/ .el-upload-list__item-actions {
		width: var(--width);
		height: var(--height);
		border-radius: 0;
	}
	
	/deep/ .hide .el-upload--picture-card {
		display: none;
	}
	
	/deep/.el-dialog__header {
		padding: 0;
		padding-top: 10px !important;
	}
	/deep/.el-dialog__body{
		padding: 15px !important;
	}
	
	.el-dialog__close{
		position: absolute;
		top: -15px;
		right: -15px;
	}
</style>
